import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams, Link } from 'react-router-dom';
import { Image, Col, Row, Button } from 'react-bootstrap';
import { useGetRecipeDetailsQuery } from '../slices/recipesSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import PrintRecipe from '../components/PrintRecipe';
import { FaChevronLeft, FaPrint } from "react-icons/fa";

const RecipeScreen = () => {
  const { servername: recipeServerName } = useParams();
  const contentRef = useRef(null);

  const { data: recipe, isLoading, error } = useGetRecipeDetailsQuery(recipeServerName);

  const printHandler = useReactToPrint({ contentRef });

  return (
    <>
    {recipe && (
      <div className="recipe-print">
        <PrintRecipe recipe={recipe} ref={contentRef} />
      </div>
    )}
    <div className='recipe-display active'>
      <Link to='/recipes' className='btn-recipe'><FaChevronLeft /> Back to recipes</Link> 
      <Button onClick={printHandler}><FaPrint/>Print</Button>
      { isLoading ? (
        <Loader /> 
      ) : error ? (
        <Message variant='danger'>{ error?.data?.message || error.error }</Message>
      ) : (
        <>
        <Row>
          <h1 id="recipe-title">{recipe.name}</h1>
          <p>Cook time: {recipe.cooktime}</p>
          <Col md={7}>
            {recipe.hassubrecipes ? (
              <>
                <h3>Ingredients</h3>
                {recipe.subrecipes.map((subRecipe) => (
                  <>
                    <h5>{subRecipe.name}</h5>
                    <ul className='recipe-list' key={subRecipe.name}>
                      {subRecipe.ingredients.map((ing, index) => (
                      <li key={index} dangerouslySetInnerHTML={ {__html: ing}}></li>
                    ))}
                    </ul>
                  </>
                ))}
                <h3>Method</h3>
                {recipe.baketemp && <h4>{`Preheat oven to ${recipe.baketemp}`}</h4>}
                {recipe.subrecipes.map((subRecipe, index) => (
                  <div key={index}>
                    <h5>{subRecipe.name}</h5>
                    <ol className='recipe-list'>
                      {subRecipe.method.map((step, index) => (
                      <li key={index} dangerouslySetInnerHTML={ {__html: step}}></li>
                      ))}
                    </ol>
                  </div>
                ))}
              </>
            ) :
            (
              <>
                <h3>Ingredients</h3>
                <ul className='recipe-list'>
                  {recipe.ingredients.map((ing, index) => (
                    <li key={index} dangerouslySetInnerHTML={ {__html: ing}}></li>
                  ))}
                </ul>
                <h3>Method</h3>
                {recipe.baketemp && <h4>{`Preheat oven to ${recipe.baketemp}`}</h4>}
                <ol className='recipe-list'>
                  {recipe.method.map((step, index) => (
                    <li key={index} dangerouslySetInnerHTML={ {__html: step}}></li>
                  ))}
                </ol>
              </>
            )}
            {recipe.notes[0] && (
              <>
                <h3>Notes</h3>
                <ul className='recipe-list'>
                {recipe.notes.map((note, index) => (
                    <li key={index} dangerouslySetInnerHTML={ {__html: note}}></li>
                  ))}
                </ul>
              </>
            )}
            
          </Col>
          <Col md={5} className="image-col">
            { recipe.images.length > 0 && (
              recipe.images.map((image, index) => (
                <Image src={image} alt={recipe.name} fluid className='recipe-image' key={index}/>
              ))
            )}
          </Col>
        </Row>
        <iframe width="560" height="315" src={recipe.video} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        {recipe.source && (
          <p>
            <br/>
            {`Source: ${recipe.source}`}
          </p>
        )}
        </>
      )}
    </div>
    </>
  )
}

export default RecipeScreen
