import { CATEGORIES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

const categoriesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecipeCategories: builder.query({
      query: () => ({
        url: `${CATEGORIES_URL}/recipe`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Categories']
    }),
    getVideoCategories: builder.query({
      query: () => ({
        url: `${CATEGORIES_URL}/video`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Categories']
    }),
    getPhotoCategories: builder.query({
      query: () => ({
        url: `${CATEGORIES_URL}/photo`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Categories']
    }),
    addCategories: builder.mutation({
      query: (data) => ({
        url: CATEGORIES_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Recipe'],
    }),
    deleteCategories: builder.mutation({
      query: (data) => ({
        url: CATEGORIES_URL,
        method: 'DELETE',
        body: data,
      })
    }),
    updateCategories: builder.mutation({
      query: (data) => ({
        url: CATEGORIES_URL,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Recipes'],
    })
  }),
});

export const { 
    useGetRecipeCategoriesQuery,
    useGetVideoCategoriesQuery,
    useGetPhotoCategoriesQuery,
    useAddCategoriesMutation,
    useDeleteCategoriesMutation,
    useUpdateCategoriesMutation, } = categoriesSlice;