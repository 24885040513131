import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useAddRecipeMutation } from "../../slices/recipesSlice";
import { useGetRecipeCategoriesQuery } from "../../slices/categoriesSlice";
import DashboardNav from "../../components/DashboardNav";
import Categories from "../../components/Categories";

const RecipeAddScreen = () => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [cooktime, setCooktime] = useState('');
  const [baketemp, setBaketemp] = useState('');
  const [hasSubRecipes, setHasSubRecipes] = useState(false);
  const [ing, setIng] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [methodStep, setMethodStep] = useState('');
  const [method, setMethod] = useState([]);
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);
  const [video, setVideo] = useState('');
  const [img, setImg] = useState('');
  const [images, setImages] = useState([]);
  const [source, setSource] = useState('');
  const [subRecipe, setSubRecipe] = useState({
    name: '',
    ingredients: [],
    method: []
  });
  const [subRecipes, setSubRecipes] = useState([]);
  const [addNewCategory, setAddNewCategory] = useState(false);

  const [addRecipe, { isLoading }] = useAddRecipeMutation();
  
  const { data: categories, isLoading: getCategoriesLoading } = useGetRecipeCategoriesQuery();

  const navigate = useNavigate();
  
  useEffect(() => {
    setSubRecipe({
      name: subRecipe.name,
      ingredients,
      method
    });
  }, [subRecipe.name, ingredients, method]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (category === 'new') {
      !addNewCategory && setAddNewCategory(true);
      window.alert('New category still updating, click "Add Recpipe" again to re-submit');
    } else {
      await submitRecipe();
    }
  }

  const submitRecipe = async() => {
    try {
      await addRecipe({ 
        name, 
        servername: name.toLowerCase().replaceAll(' ', '_'), 
        category, 
        cooktime,
        baketemp,
        hassubrecipes: hasSubRecipes,
        subrecipes: subRecipes,
        ingredients, 
        method, 
        notes, 
        video, 
        images,
        source
       }).unwrap();
      toast.success('Recipe added');
      navigate('/dashboard/recipes');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }  

  const addIng = (e) => {
    e.preventDefault();
    if (ing === '') {
      return;
    }
    setIngredients([...ingredients, ing]);
    setIng('');
    document.getElementById('ingredients').focus();
  }

  const addMethod = (e) => {
    e.preventDefault();
    if (methodStep === '') {
      return;
    }
    setMethod([...method, methodStep]);
    setMethodStep('');
    document.getElementById('method').focus();
  }

  const addNote = (e) => {
    e.preventDefault();
    if (note === '') {
      return;
    }
    setNotes([...notes, note]);
    setNote('');
    document.getElementById('notes').focus();
  }

  const addImage = (e) => {
    e.preventDefault();
    if (img === '') {
      return;
    }
    setImages([...images, img]);
    setImg('');
    document.getElementById('images').focus();
  }

  const handleCheck = (e) => {
    const subRecOptions = document.querySelectorAll('.sub-rec-options');
  
    if (e.target.checked) {
      subRecOptions.forEach(option => {
        option.classList.add('active');
        option.classList.remove('hidden');
      })
      setHasSubRecipes(true);
    } else {
      subRecOptions.forEach(option => {
        option.classList.add('hidden');
        option.classList.remove('active');
      })
      setHasSubRecipes(false);
    }
  }

  const subRecipeHandler = () => {
    setSubRecipes([...subRecipes, subRecipe]);
    setIngredients([]);
    setMethod([]);
    setSubRecipe(prevState => (
      {...prevState, name: ''}
    ))
    document.getElementById('sub-recipe-names').focus();
  }

  return (
    <>
      <DashboardNav />
      <h2>Add New Recipe</h2>
          <Form.Group controlId="name" className="my-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}>
            </Form.Control>
          </Form.Group>

          {getCategoriesLoading ? <Loader /> : (
          <Categories
            categories={categories}
            category={category}
            setCategory={setCategory}
            addNewCategory={addNewCategory}
            name='recipe'>  
          </Categories>
          )}

          <Form.Group controlId="cooktime" className="my-2">
            <Form.Label>Cooktime</Form.Label>
            <Form.Control
              type='text'
              placeholder="Cooktime"
              value={cooktime}
              onChange={(e) => setCooktime(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="baketemp" className="my-2">
            <Form.Label>Bake temperature - if you want to preheat oven before step one</Form.Label>
            <Form.Control
              type='text'
              placeholder="Enter preheat temp"
              value={baketemp}
              onChange={(e) => setBaketemp(e.target.value)}>
            </Form.Control>
          </Form.Group>

          <Form.Check
            type='checkbox'
            id='sub-recipes-check'
            label='This recipe has sub-recipes'
            onClick={handleCheck}
          />

          <div id="sub-recipe-names" className="hidden sub-rec-options">
            <Form.Group controlId="subRecipe" className="my-2">
              <Form.Label>Sub-recipe name</Form.Label>
              <Form.Control
                type='text'
                placeholder="Sub-recipe name"
                value={subRecipe.name}
                onChange={(e) => setSubRecipe(prevState => (
                  {...prevState, name: e.target.value}
                ))}>
              </Form.Control>
            </Form.Group>
          </div>

          <Form onSubmit={addIng}>
            <Form.Group controlId="ingredients" className="my-2">
              <Form.Label>Ingredients</Form.Label>
              <Form.Control
                type='text'
                placeholder="Add ingredient"
                value={ing}
                onChange={(e) => setIng(e.target.value)}>
              </Form.Control>
            </Form.Group>
            <Button 
              variant="dark"
              type="submit">
              Add ingredient
            </Button>
          </Form>
          <ul>
            {ingredients.map((ingredient, index) => (
              <li key={index} dangerouslySetInnerHTML={ {__html: ingredient}}></li>
            ))}
          </ul>
          
          <Form onSubmit={addMethod}>
            <Form.Group controlId="method" className="my-2">
              <Form.Label>Method</Form.Label>
              <Form.Control
                as="textarea" 
                rows={3}
                placeholder="Add step"
                value={methodStep}
                onChange={(e) => setMethodStep(e.target.value)}>
              </Form.Control>
            </Form.Group>
            <Button 
              variant="dark"
              type="submit">
              Add step
            </Button>
          </Form>
          <ul>
            {method.map((step, index) => (
              <li key={index} dangerouslySetInnerHTML={ {__html: step}}></li>
            ))}
          </ul>
          <Button 
            className="sub-rec-options hidden"
            variant="primary"
            type="submit"
            onClick={subRecipeHandler}>
            Add sub-recipe
          </Button>

          <div className="sub-recipe-list sub-rec-options hidden">
            {hasSubRecipes && subRecipes.map((recipe) => (
            <div className="sub-recipe" key={recipe.name}>
              <h5>{recipe.name}</h5>
              <h6>Ingredients</h6>
              <ul>
                {recipe.ingredients.map((ingredient, index) => (
                  <li key={index} dangerouslySetInnerHTML={ {__html: ingredient}}></li>
                ))}
              </ul>
              <h6>Method</h6>
              <ul>
                {recipe.method.map((step, index) => (
                  <li key={index} dangerouslySetInnerHTML={ {__html: step}}></li>
                ))}
              </ul>
            </div>))}
          </div>
          
          <div className="sub-rec-options active">
            <Form onSubmit={addNote}>
              <Form.Group controlId="notes" className="my-2">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea" 
                  rows={3}
                  placeholder="Add note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}>
                </Form.Control>
              </Form.Group>
              <Button 
                variant="dark"
                type="submit">
                Add note
              </Button>
            </Form>
            <ul>
              {notes.map((note, index) => (
                <li key={index} dangerouslySetInnerHTML={ {__html: note}}></li>
              ))}
            </ul>

            <Form.Group controlId="video" className="my-2">
              <Form.Label>Video</Form.Label>
              <Form.Control
                type='text'
                placeholder="video"
                value={video}
                onChange={(e) => setVideo(e.target.value)}>
              </Form.Control>
            </Form.Group>

            <Form onSubmit={addImage}>
              <Form.Group controlId="images" className="my-2">
                <Form.Label>Images</Form.Label>
                <Form.Control
                  type='text'
                  placeholder="Add note"
                  value={img}
                  onChange={(e) => setImg(e.target.value)}>
                </Form.Control>
              </Form.Group>
              <Button 
                variant="dark"
                type="submit">
                Add image
              </Button>
            </Form>
            <ul>
              {images.map((note, index) => (
                <li key={index}>{note}</li>
              ))}
            </ul>

            <Form.Group controlId="source" className="my-2">
              <Form.Label>Source</Form.Label>
              <Form.Control
                type='text'
                placeholder="source"
                value={source}
                onChange={(e) => setSource(e.target.value)}>
              </Form.Control>
            </Form.Group>

            <Button onClick={submitHandler} variant="primary" className="my-2">
              Add Recipe
            </Button>
          </div>
          { isLoading && <Loader />}
    </>
  )
}

export default RecipeAddScreen
