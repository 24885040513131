import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useAddVideoMutation } from '../../slices/videosSlice';
import { useGetVideoCategoriesQuery } from "../../slices/categoriesSlice";
import DashboardNav from "../../components/DashboardNav";
import Categories from "../../components/Categories";

const VideoAddScreen = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [video, setVideo] = useState('');
  const [description, setDescription] = useState('');
  const [addNewCategory, setAddNewCategory] = useState(false);

  const [addVideo, { isLoading }] = useAddVideoMutation();
  const { data: categories, isLoading: getCategoriesLoading } = useGetVideoCategoriesQuery();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (category === 'new') {
      !addNewCategory && setAddNewCategory(true);
      window.alert('New category still updating, click "Add Video" again to re-submit');
    } else {
      try {
        await addVideo({ 
          title, 
          category,  
          video, 
          description
         }).unwrap();
        toast.success('Video added');
        navigate('/dashboard/videos');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <h2>Add New Video</h2>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>

        {getCategoriesLoading ? <Loader /> : (
        <Categories
          categories={categories}
          category={category}
          setCategory={setCategory}
          addNewCategory={addNewCategory}
          name='video'>  
        </Categories>
        )}

        <Form.Group controlId="video" className="my-2">
          <Form.Label>Video</Form.Label>
          <Form.Control
            type='text'
            placeholder="video"
            value={video}
            onChange={(e) => setVideo(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary" className="my-2">
          Add Video
        </Button>
        { isLoading && <Loader />}
      </Form>
    </>
  )
}

export default VideoAddScreen
