import LatestVideos from '../components/LatestVideos';
import LatestRecipe from '../components/LatestRecipe';
import { Image } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import PhotoCarousel from '../components/PhotoCarousel';
import { useGetPostsQuery } from '../slices/postsSlice';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(createdAt) {
    const date = new Date(createdAt);
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

const HomeScreen = () => {
    const { data: posts, isLoading, error } = useGetPostsQuery();

    return (
        <div className="container">
            <h1>Welcome</h1>
            <section id='sidebar'>
                <h2>Recent Photos</h2>
                <PhotoCarousel />
                <h2>Latest Videos</h2>
                <LatestVideos />
                <h2>Check out this recipe</h2>
                <LatestRecipe />
            </section>
            <section id="main">
                {isLoading ? <Loader /> :
                error ? <Message></Message> :
                (
                posts.map((post) => (
                <div className="post" key={post._id}>
                    <h3>{post.title}</h3>
                    <h5>Posted by: {post.name}</h5>
                    <h6>{formatDate(post.createdAt)}</h6>
                    <p dangerouslySetInnerHTML={ {__html: post.body}}></p>
                    {post.images.length > 0 && (
                        <div className="post-photos">
                            {post.images.map((image, index) => <Image key={index} className="photo" src={image} alt={image} />)}
                        </div>
                    )}
                    {post.video && <iframe width="400" height="225" src={post.video} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                </div>
                )))}
            </section>
        </div>
    )
}

export default HomeScreen
