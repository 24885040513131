import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { useAddPhotoMutation } from "../slices/photosSlice";
import { useGetPhotoCategoriesQuery } from "../slices/categoriesSlice";
import ImageUpload from "../components/ImageUpload";
import Categories from "./Categories";

const AddPhoto = ({ setId, setThumbnail }) => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [imageId, setImageId] = useState('');
  const [description, setDescription] = useState('');
  const [cloudflareId, setCloudflareId] = useState('');
  const [addNewCategory, setAddNewCategory] = useState(false);

  const [addPhoto, { isLoading }] = useAddPhotoMutation();

  const { data: categories, isLoading: getCategoriesLoading } = useGetPhotoCategoriesQuery();
  
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (category === 'new') {
      !addNewCategory && setAddNewCategory(true);
      window.alert('New category still updating, click "Add Photo" again to re-submit');
    } else {
      try {
        const res = await addPhoto({ 
          title, 
          category, 
          imageId,
          description,
          cloudflareId
         }).unwrap();
        setDescription('');
        setId(res.image);
        toast.success(`Photo added`);
        navigate('/dashboard/gallery');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

return (
  <div className='add-photo'>
    <h4>Add New Photo</h4>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>
        
        {getCategoriesLoading ? <Loader /> : (
        <Categories
          categories={categories}
          category={category}
          setCategory={setCategory}
          addNewCategory={addNewCategory}
          name='photo'>  
        </Categories>
        )}

        <ImageUpload
          imageId={imageId}
          setImageId={setImageId}
          setCloudflareId={setCloudflareId}
          setThumb={setThumbnail}>
        </ImageUpload>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Button onClick={submitHandler} variant="primary" className="button my-2">
          Add Photo
        </Button>
        { isLoading && <Loader />}
    </div>
  )
}

export default AddPhoto
