import { useGetVideosQuery } from '../slices/videosSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';

const VideosScreen = () => {
  const { data: videos, isLoading, error } = useGetVideosQuery();

  return (
    <div className="container">
        <h1>Videos</h1>
        { isLoading ? (
          <Loader />
          ) : error ? (
          <Message variant='danger'>{ error?.data?.message || error.error }</Message>
          ) : (
          <>
            {videos.map((category) => (
              <div key={category[0]._id}>
                <h3>{category[0].category}</h3>
                <div className="videos">
                  {category.map((video) => (
                    <div key={video._id} className="video">
                      <h4>{video.title}</h4>
                      <iframe width="400" height="225" src={video.video} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
    </div>
  )
}

export default VideosScreen
