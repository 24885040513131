import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetVideosQuery, useDeleteVideoMutation } from "../../slices/videosSlice";
import { toast } from 'react-toastify';
import DashboardNav from "../../components/DashboardNav";

const VideoListScreen = () => {
  const { data: videos, refetch, isLoading, error } = useGetVideosQuery();

  const [deleteVideo, { isLoading: loadingDelete }] = useDeleteVideoMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Delete video?')) {
      try {
        await deleteVideo(id);
        refetch();
        toast.success('Video deleted');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <Row className="align-items-center">
        <Col>
          <h1>Videos</h1>
        </Col>
        <Col>
          <LinkContainer to='/dashboard/videos/addvideo'>
            <Button variant="success">
              <FaPlus /> Add Video
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      { isLoading ? <Loader /> : error ? <Message variant="danger"></Message> : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>TITLE</th>
                <th>CATEGORY</th>
                <th>VIDEO</th>
                <th>DESCRIPTION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {videos.map((category) => (
                category.map((video) => (
                  <tr key={video._id}>
                    <td>{video.title}</td>
                    <td>{video.category}</td>
                    <td>{video.video}</td>
                    <td>{video.description}</td>
                    <td>
                      <LinkContainer to={`/dashboard/videos/edit/${video._id}`}>
                        <Button variant="light" className="btn-sm mx-2">
                          <FaEdit /> 
                        </Button>
                      </LinkContainer>
                      <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(video._id)}>
                        <FaTrash style={{color: 'white'}}/>
                      </Button>
                    </td>
                  </tr>
                ))
              )
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default VideoListScreen

