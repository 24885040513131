import { Form, Image } from 'react-bootstrap';
import { useState } from 'react';
import { useUploadImageMutation } from '../slices/photosSlice';
import { toast } from 'react-toastify';
import Loader from './Loader';

const ImageUpload = ({ imageId, setImageId, setCloudflareId, setThumb }) => {
    const [thumbnail, setThumbnail] = useState('');

    const [uploadImage, { isLoading: loadingImage }] = useUploadImageMutation();

    const uploadFileHandler = async (e) => {
        const formData = new FormData();
        formData.append('image', e.target.files[0]);
        try {
        const res = await uploadImage(formData).unwrap();
        setCloudflareId(res.result.id);
        setImageId(res.result.id);
        setThumbnail(res.result.variants[0]);
        setThumb(res.result.variants[0]);
        } catch (error) {
        toast.error(error?.data?.message || error.error);
        }
    }

    return (
    <Form.Group controlId="image" className="my-2">
      <Form.Label>Image</Form.Label>
      <Form.Control 
        type='text'
        placeholder='Enter image URL'
        value={imageId}
        onChange={(e) => setImageId(e.target.value)}
      />
      <Form.Control
        className='file-input'
        type='file'
        label='Choose file'
        onChange={uploadFileHandler}
      />
      {loadingImage && <Loader />}
      {thumbnail && <Image className="photo" src={thumbnail} alt='No image yet' fluid />}
    </Form.Group>
    );
};

export default ImageUpload;
