import { useState } from "react";
import { Form, Button, Image } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useAddPostMutation } from "../../slices/postsSlice";
import DashboardNav from "../../components/DashboardNav";
import AddPhoto from "../../components/AddPhoto";
import { useEffect } from "react";

const PostAddScreen = () => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [video, setVideo] = useState('');
  const [img, setImg] = useState('');
  const [images, setImages] = useState([]);
  const [isJournal, setIsJournal] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnails, setThumbnails] = useState([]);

  const [addPost, { isLoading }] = useAddPostMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await addPost({ 
        name, 
        title, 
        body, 
        video, 
        images,
        isjournal: isJournal,
       }).unwrap();
      toast.success('Post added');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

  useEffect(() => {
    if (img === '') {
      return;
    }
    setImages([...images, img]);
    setImg('');
  }, [img, images]);

  useEffect(() => {
    if (thumbnail === '') {
      return;
    }
    setThumbnails([...thumbnails, thumbnail]);
    setThumbnail('');
  }, [thumbnail, thumbnails]);

  const handleCheck = (e) => {
    setIsJournal(e.target.checked);
  }

  return (
    <>
      <DashboardNav />

      <h2>Add New post</h2>

      <Form.Check
        type='checkbox'
        id='is-journal-check'
        label='This is a garden journal entry (Katy, probably check this box)'
        onClick={handleCheck}
      />

      <Form.Group controlId="name" className="my-2">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type='text'
          placeholder="Enter post author"
          value={name}
          onChange={(e) => setName(e.target.value)}>
        </Form.Control>
      </Form.Group>
      
      <Form.Group controlId="title" className="my-2">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type='text'
          placeholder="Enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="body" className="my-2">
        <Form.Label>Post Body</Form.Label>
        <Form.Control
          as="textarea" 
          rows={15}
          placeholder="body"
          value={body}
          onChange={(e) => setBody(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <AddPhoto
       setId={setImg}
       setThumbnail={setThumbnail}>
      </AddPhoto>

      <ul>
        {thumbnails.map((image, index) => (
          <li key={index}><Image src={image}></Image></li>
        ))}
      </ul>

      <Form.Group controlId="video" className="my-2">
        <Form.Label>Video</Form.Label>
        <Form.Control
          type='text'
          placeholder="video"
          value={video}
          onChange={(e) => setVideo(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Button onClick={submitHandler} variant="primary" className="my-2">
        Add post
      </Button>
      { isLoading && <Loader />}
    </>
  )
}

export default PostAddScreen