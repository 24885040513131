import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetRecipesQuery, useDeleteRecipeMutation } from "../../slices/recipesSlice";
import { toast } from 'react-toastify';
import DashboardNav from "../../components/DashboardNav";

const RecipesListScreen = () => {
  const { data: recipes, refetch, isLoading, error } = useGetRecipesQuery();

  const [deleteRecipe, { isLoading: loadingDelete }] = useDeleteRecipeMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Delete recipe?')) {
      try {
        await deleteRecipe(id);
        refetch();
        toast.success('Recipe deleted');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <Row className="align-items-center">
        <Col>
          <h1>Recipes</h1>
        </Col>
        <Col>
          <LinkContainer to='/dashboard/recipes/addrecipe'>
            <Button variant="success">
              <FaPlus /> Add Recipe
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      { isLoading ? <Loader /> : error ? <Message variant="danger"></Message> : (
        <>
        {recipes.map((category) => (
          <div key={category[0]._id}>
            <h4 className="my-2">{category[0].category}</h4>
            {category.map((recipe) => (
              <Row key={recipe._id} className="my-1">
                <Col>
                  <LinkContainer to={`/dashboard/recipes/edit/${recipe.servername}`}>
                    <Button variant="light" className="btn-sm mx-2 recipe-link">
                      {recipe.name}
                      <FaEdit /> 
                    </Button>
                  </LinkContainer>
                </Col>
                <Col>
                  <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(recipe._id)}>
                    <FaTrash style={{color: 'white'}}/>
                  </Button>
                </Col>
              </Row>
            ))}
          </div>
        ))}
        </>
      )}
    </>
  )
}

export default RecipesListScreen
