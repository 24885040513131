import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Row, Col } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useUpdatePostMutation, useGetPostDetailsQuery } from "../../slices/postsSlice";
import { FaTimes } from "react-icons/fa";
import DashboardNav from "../../components/DashboardNav";
import Message from "../../components/Message";

const PostEditScreen = () => {
  const { id: postId } = useParams();

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [video, setVideo] = useState('');
  const [img, setImg] = useState('');
  const [images, setImages] = useState([]);
  const [createdAt, setCreatedAt] = useState('');
  const [isJournal, setIsJournal] = useState(false);

  const { data: postData, isLoading, error } = useGetPostDetailsQuery(postId);

  const [updatePost, { isLoading: loadingUpdate }] = useUpdatePostMutation();

  const navigate = useNavigate();

  useEffect(() => { 
    if (postData) {
      setName(postData.name)
      setTitle(postData.title);
      setBody(postData.body);
      setImages(postData.images);
      setVideo(postData.video);
      setCreatedAt(postData.createdAt);
      setIsJournal(postData.isjournal);
    }
   }, [postData]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updatePost({ 
        _id: postId,
        name, 
        title, 
        body, 
        video, 
        images,
        isjournal: isJournal,
       }).unwrap();
      toast.success('Post updated');
      navigate('/dashboard/posts')
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

  const addImg = (e) => {
    if (img === '') {
      return;
    }
    e.preventDefault();
    setImages([...images, img]);
    setImg('');
    document.getElementById('images').focus();
  }
  
  const handleCheck = (e) => {
    setIsJournal(e.target.checked);
  }

  return (
    <>
      <DashboardNav />

      <h2>Update post</h2>
      {isLoading ? <Loader /> : 
      error ? <Message variant='danger'></Message> : (
      <>

      <Form.Check
        type='checkbox'
        id='is-journal-check'
        label='This is a garden journal entry (Katy, probably check this box)'
        checked={isJournal}
        onChange={handleCheck}
      />

      <Form.Group controlId="name" className="my-2">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type='text'
          placeholder="Enter post author"
          value={name}
          onChange={(e) => setName(e.target.value)}>
        </Form.Control>
      </Form.Group>
      
      <Form.Group controlId="title" className="my-2">
        <Form.Label>Title</Form.Label>
        <Form.Control
          type='text'
          placeholder="Enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="body" className="my-2">
        <Form.Label>Post Body</Form.Label>
        <Form.Control
          as="textarea" 
          rows={15}
          placeholder="body"
          value={body}
          onChange={(e) => setBody(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Form onSubmit={addImg}>
        <Form.Group controlId="images" className="my-2">
          <Form.Label>Images</Form.Label>
          <Form.Control
            type='text'
            placeholder="Add image path"
            value={img}
            onChange={(e) => setImg(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Button 
          variant="dark"
          type="submit">
          Add image
        </Button>
      </Form>
      <ul>
        {images.map((image, index) => (
          <Row key={index}>
            <Col xs={10}>
              <li >{image}</li>
            </Col>
            <Col xs={1}>
              <Button variant="danger" size="sm" onClick={() => setImages(images.slice(0, index).concat(images.slice(index + 1)))}>
                <FaTimes style={{color: 'white'}}/>
              </Button>
            </Col>
          </Row>
        ))}
      </ul>

      <Form.Group controlId="video" className="my-2">
        <Form.Label>Video</Form.Label>
        <Form.Control
          type='text'
          placeholder="video"
          value={video}
          onChange={(e) => setVideo(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Form.Group controlId="createdAt" className="my-2">
        <Form.Label>Created At</Form.Label>
        <Form.Control
          type='text'
          placeholder="Created At"
          value={createdAt}
          onChange={(e) => setCreatedAt(e.target.value)}>
        </Form.Control>
      </Form.Group>

      <Button onClick={submitHandler} variant="primary" className="my-2">
        Update post
      </Button>
      { loadingUpdate && <Loader />}
      </>
      )}
    </>
  );  
}

export default PostEditScreen

