import { useState, useEffect, useCallback } from "react";
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useUpdateCategoriesMutation } from "../slices/categoriesSlice";
import Loader from "./Loader";

const Categories = ({ categories, category, setCategory, addNewCategory, setAddNewCategory, name }) => {
    const [newCategory, setNewCategory] = useState('');
      
    const [updateCategories, { isLoading } ] = useUpdateCategoriesMutation();
        
    const updateCategoriesAPI = useCallback(async (updatedCategories) => {
        try {
            await updateCategories({ 
            name,
            newCategories: updatedCategories, 
            }).unwrap();
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
    }, [name, updateCategories]);
    
    const addCategory = useCallback(async () => {
        if (categories.includes(newCategory.trim())) {
            window.alert('Category already exists');
            setAddNewCategory(false);
            return;
        }
        const updatedCategories = [...categories, newCategory];
        setCategory(newCategory);
        await updateCategoriesAPI(updatedCategories); 
    }, [categories, newCategory, updateCategoriesAPI, setCategory, setAddNewCategory]);
    
    useEffect(() => {
        if (addNewCategory) {
            addCategory();
        }
    }, [addNewCategory, addCategory]);

return (
    <>
    <h6>Choose Category</h6>
    {categories && categories.map((cat, index) => (
        <Form.Check
        key={index}
        type='radio'
        name='category'
        label={cat}
        value={cat}
        checked={category === cat}
        onChange={(e) => setCategory(e.target.value)}>
        </Form.Check>))
    }

    <Form.Check
    type='radio'
    name='category'
    label='New category'
    value='new'
    onChange={(e) => setCategory(e.target.value)}>
    </Form.Check>
    <Form.Control
    disabled = {category !== 'new'}
    type='text'
    placeholder="Enter new category"
    value={newCategory}
    onChange={(e) => setNewCategory(e.target.value)}>
    </Form.Control>

    {isLoading && <Loader/>}
    </>
  )
}

export default Categories
