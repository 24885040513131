import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetAllPhotosQuery, useDeletePhotoMutation } from "../../slices/photosSlice";
import { toast } from 'react-toastify';
import DashboardNav from "../../components/DashboardNav";

const PhotoListScreen = () => {
  const { data: photos, refetch, isLoading, error } = useGetAllPhotosQuery();

  const [deletePhoto, { isLoading: loadingDelete }] = useDeletePhotoMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Delete photo?')) {
      try {
        const res = await deletePhoto(id);
        refetch(); 
        toast.success(res.message);
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <Row className="align-items-center">
        <Col>
          <h1>Photos</h1>
        </Col>
        <Col>
          <LinkContainer to='/dashboard/gallery/addphoto'>
            <Button variant="success">
              <FaPlus /> Add photo
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      { isLoading ? <Loader /> : error ? <Message variant="danger"></Message> : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>TITLE</th>
                <th>CATEGORY</th>
                <th>IMAGE</th>
                <th>DESCRIPTION</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="photo-list">
              {photos.map((photo) => (
                <tr key={photo._id}>
                  <td>{photo.title}</td>
                  <td>{photo.category}</td>
                  <td>{photo.image}</td>
                  <td>{photo.description}</td>
                  <td>
                    <LinkContainer to={`/dashboard/gallery/edit/${photo._id}`}>
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit /> 
                      </Button>
                    </LinkContainer>
                    <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(photo._id, photo.image)}>
                      <FaTrash style={{color: 'white'}}/>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default PhotoListScreen


