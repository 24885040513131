import DashboardNav from "../../components/DashboardNav";
import AddPhoto from '../../components/AddPhoto';

const PhotosAddScreen = () => {
  const setImg = (img) => {
    console.log(img);
  }

  const setThumbnail = (thumb) => {
    console.log(thumb);
  }
  

return (
  <>
    <DashboardNav />
    <AddPhoto
       setId={setImg}
       setThumbnail={setThumbnail}>
    </AddPhoto>
  </>
  )
}

export default PhotosAddScreen
