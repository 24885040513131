import { useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import { useGetDisplayPhotosQuery } from '../slices/photosSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { FaChevronLeft } from 'react-icons/fa';

const GalleryScreen = () => {
  const [fullscreenCategory, setFullscreenCategory] = useState([]);
  const [fullscreenIndex, setFullscreenIndex] = useState(0);
  const fullscreenImageRef = useRef(null);
  const fullscreenTitle = useRef('Title');
  const fullscreenDesc = useRef('Desc');
  const fullscreenDisplayCategory = useRef('DisplayCategory');

  const { data: photos, isLoading, error } = useGetDisplayPhotosQuery();

  const photoClick = (e, photo, index, category) => {
    e.preventDefault();

    // Setting category uses photo details for just that category, index sets photo to start at for slide-show
    setFullscreenCategory(category);
    setFullscreenIndex(index);

    toggleFullscreen();
    fullscreenImageRef.current && (fullscreenImageRef.current.src = `${photo.image}/public`);
    fullscreenTitle.current && (fullscreenTitle.current.innerHTML = photo.title);
    fullscreenDesc.current && (fullscreenDesc.current.innerHTML = photo.description);
    fullscreenDisplayCategory.current && (fullscreenDisplayCategory.current.innerHTML = category[0].category);
  }

  const leftClick = () => {
    let index = fullscreenIndex - 1;
    if (index < 0) {
      index = fullscreenCategory.length - 1;
    }
    fullscreenImageRef.current && (fullscreenImageRef.current.src = `${fullscreenCategory[index].image}/public`);
    fullscreenTitle.current && (fullscreenTitle.current.innerHTML = fullscreenCategory[index].title);
    fullscreenDesc.current && (fullscreenDesc.current.innerHTML = fullscreenCategory[index].description);
    setFullscreenIndex(index);
  }

  const toggleFullscreen = () => {
    const main = document.querySelector('.gallery-main');
    const fullscreenContainer = document.querySelector('.fullscreen-container');
    main.classList.toggle('active');
    fullscreenContainer.classList.toggle('active');

  }

  const rightClick = () => {
    let index = fullscreenIndex + 1;
    if (index > (fullscreenCategory.length - 1)) {
      index = 0;
    }
    fullscreenImageRef.current && (fullscreenImageRef.current.src = `${fullscreenCategory[index].image}/public`);
    fullscreenTitle.current && (fullscreenTitle.current.innerHTML = fullscreenCategory[index].title);
    fullscreenDesc.current && (fullscreenDesc.current.innerHTML = fullscreenCategory[index].description);
    setFullscreenIndex(index);
  }

  return (
    <div className="container">
      <div className='fullscreen-container'>
        <h2 ref={fullscreenDisplayCategory} className='fullscreen-category'>Category</h2>
        <Image ref={fullscreenImageRef} className='fullscreen-image'></Image>
        <h2 ref={fullscreenTitle} className='fullscreen-title'>Title</h2>
        <h4 ref={fullscreenDesc} className='fullscreen-desc'>Description</h4>
        
        <button className="arrow left-arrow" id="left" onClick={leftClick}><i className="fas fa-arrow-left"></i></button>
        <button className="arrow right-arrow" id="right" onClick={rightClick}><i className="fas fa-arrow-right"></i></button>
        <button className='fullscreen-back-button arrow' onClick={toggleFullscreen}><FaChevronLeft/>Back</button>
      </div>
      <section className='gallery-main active' id="main">
        <h1>Gallery</h1>
        { isLoading ? (
          <Loader />
          ) : error ? (
          <Message variant='danger'>{ error?.data?.message || error.error }</Message>
          ) : (
          <>
            {photos.map((category) => (
              <div key={category[0]._id}>
                <h3>{category[0].category}</h3>
                <div className="photos">
                  {category.map((photo, index) => (
                    <div key={photo._id} className='photo-container' onClick={(e) => photoClick(e, photo, index, category)}>
                      <Image className="photo" src={`${photo.image}/public`} alt={photo.title} />
                      <h4 id='photo-title'>{photo.title}</h4>
                      <p id='photo-desc'>{photo.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
      </section>
    </div>
  )
}

export default GalleryScreen
