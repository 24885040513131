import React from "react"

const PrintRecipe = React.forwardRef(({recipe}, ref) => (

    <div ref={ref}>
      <h1 id="recipe-title">{recipe.name}</h1>
      <p>Cook time: {recipe.cooktime}</p>
        {recipe.hassubrecipes ? (
          <>
            <h3>Ingredients</h3>
            {recipe.subrecipes.map((subRecipe) => (
              <>
                <h5>{subRecipe.name}</h5>
                <ul className='recipe-list' key={subRecipe.name}>
                  {subRecipe.ingredients.map((ing, index) => (
                  <li key={index} dangerouslySetInnerHTML={ {__html: ing}}></li>
                ))}
                </ul>
              </>
            ))}
            <h3>Method</h3>
            {recipe.baketemp && <h4>{`Preheat oven to ${recipe.baketemp}`}</h4>}
            {recipe.subrecipes.map((subRecipe, index) => (
              <div key={index}>
                <h5>{subRecipe.name}</h5>
                <ol className='recipe-list'>
                  {subRecipe.method.map((step, index) => (
                  <li key={index} dangerouslySetInnerHTML={ {__html: step}}></li>
                  ))}
                </ol>
              </div>
            ))}
          </>
        ) :
        (
          <>
            <h3>Ingredients</h3>
            <ul className='recipe-list ingredients'>
              {recipe.ingredients.map((ing, index) => (
                <li key={index} dangerouslySetInnerHTML={ {__html: ing}}></li>
              ))}
            </ul>
            <h3>Method</h3>
            {recipe.baketemp && <h4>{`Preheat oven to ${recipe.baketemp}`}</h4>}
            <ol className='recipe-list'>
              {recipe.method.map((step, index) => (
                <li key={index} dangerouslySetInnerHTML={ {__html: step}}></li>
              ))}
            </ol>
          </>
        )}
        {recipe.notes[0] && (
          <>
            <h3>Notes</h3>
            <ul className='recipe-list'>
            {recipe.notes.map((note, index) => (
                <li key={index} dangerouslySetInnerHTML={ {__html: note}}></li>
              ))}
            </ul>
          </>
        )}
    {recipe.source && (
      <p>
        <br/>
        {`Source: ${recipe.source}`}
      </p>
    )}
    </div>
))

export default PrintRecipe
