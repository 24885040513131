import Loader from "./Loader";
import Message from "./Message";
import { useGetLatestRecipeQuery } from "../slices/recipesSlice";

const LatestRecipe = () => {
  const { data: recipe, isLoading, error } = useGetLatestRecipeQuery();
  console.log(recipe)

  return isLoading ? <Loader /> : 
    error ? <Message variant='danger'>{ error?.data?.message || error.error }</Message> : 
    (
    <div id='recipe-home'>
      <a href={`/recipes/${recipe.servername}`}><h4>{recipe.name}</h4></a>
    </div>
    )
}

export default LatestRecipe