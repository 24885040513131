import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button, Image } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useGetPhotoDetailsQuery, useUpdatePhotoMutation } from "../../slices/photosSlice";
import { useGetPhotoCategoriesQuery } from "../../slices/categoriesSlice";
import DashboardNav from "../../components/DashboardNav";
import Message from "../../components/Message";
import Categories from "../../components/Categories";

const PhotosEditScreen = () => {
  const { id: photoId } = useParams();

  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [addNewCategory, setAddNewCategory] = useState(false);

  const { data: photoData, isLoading, error } = useGetPhotoDetailsQuery(photoId);

  const [updatePhoto, { isLoading: loadingUpdate }] = useUpdatePhotoMutation();
  const { data: categories, isLoading: getCategoriesLoading } = useGetPhotoCategoriesQuery();

  const navigate = useNavigate();

  useEffect(() => { 
    if (photoData) {
      setTitle(photoData.title);
      setCategory(photoData.category);
      setImage(photoData.image);
      setDescription(photoData.description);
    }
   }, [photoData]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (category === 'new') {
      !addNewCategory && setAddNewCategory(true);
      window.alert('New category still updating, click "Update Photo" again to re-submit');
    } else {
      try {
        const updatedPhoto = {
          _id: photoId,
          title,
          category,
          description,
          image,
        }

        await updatePhoto(updatedPhoto);
        toast.success('Photo updated');
        navigate('/dashboard/gallery');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

return (
  <>
    <DashboardNav />
    <h2>Update Photo</h2>
      {isLoading ? <Loader /> : 
      error ? <Message variant='danger'></Message> : (
      <>
      <Image className="photo" src={`${image}/carousel`} alt='No image yet' fluid />

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>
        
        {getCategoriesLoading ? <Loader /> : (
        <Categories
          categories={categories}
          category={category}
          setCategory={setCategory}
          addNewCategory={addNewCategory}
          setAddNewCategory={setAddNewCategory}
          name='photo'>  
        </Categories>
        )}

        <Form.Group controlId="image" className="my-2">
          <Form.Label>Image</Form.Label>
          <Form.Control
            type='text'
            placeholder="image"
            value={image}
            onChange={(e) => setImage(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary" className="my-2">
          Update Photo
        </Button>
        { loadingUpdate && <Loader />}
      </Form>
      </>
      )}
    </>
  )
}

export default PhotosEditScreen
