import { Image, Table } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useGetJournalEntriesQuery } from '../slices/postsSlice';

function formatDate(createdAt) {
    const date = new Date(createdAt);
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

const JournalScreen = () => {
    const { data: journalEntries, isLoading, error } = useGetJournalEntriesQuery();
    console.log(journalEntries)

    return (
      <>
          {isLoading ? <Loader /> :
          error ? <Message></Message> :
          (
            <Table striped hover className='journal-table'>
            <colgroup>
            <col span="1" style={{width: '8%'}}></col>
            <col span="1" style={{width: '10%'}}></col>
            <col span="1" style={{width: '7%'}}></col>
            <col span="1" style={{width: '30%'}}></col>
            <col span="1" style={{width: '30%'}}></col>
            <col span="1" style={{width: '15%'}}></col>
            </colgroup>
            <thead>
              <tr>
                <th>DATE</th>
                <th>TITLE</th>
                <th>AUTHOR</th>
                <th>BODY</th>
                <th>IMAGES</th>
                <th>VIDEO</th>
              </tr>
            </thead>
            <tbody className="photo-list">
              {journalEntries.map((journal) => (
                <tr key={journal._id}>
                  <td>{formatDate(journal.createdAt)}</td>
                  <td>{journal.title}</td>
                  <td>{journal.name}</td>
                  <td>{journal.body}</td>
                  <td>{journal.images.length > 0 ? (<div className="journal-photos">
                  {journal.images.map((photo, index) => (
                      <Image key={index} className="journal-photo" src={photo} alt={photo} />
                    ))}
                  </div>) : (
                      ''
                    )
                  }
                  </td>
                  <td>{journal.video && <iframe width="400" height="225" src={journal.video} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}</td>
                </tr>
              ))}
            </tbody>
          </Table>)}
      </>
    )
}

export default JournalScreen
